<template>
  <div :class="['form-group has-feedback', { 'has-error': !isValid }]">
    <label :for="id" :class="{ 'sr-only': !showLabel }">{{ $tc(label) }}</label>
    <input
      :id="id"
      class="form-control"
      :type="passwordVisible ? 'text' : 'password'"
      :pattern="computedPattern"
      autocomplete="off"
      v-model="password"
      @input="validatePassword"
      @focus="onInputFocus"
      @blur="onInputBlur"
      v-bind="$attrs"
      ref="input"
      required
      data-testid="password"
    />
    <span
      class="form-control-feedback"
      :class="feedbackIcon"
      @click.prevent="togglePasswordVisibility"
      data-testid="toggle-visible"
    ></span>
    <transition name="fade-up">
      <ul class="help-block">
        <li
          v-for="policy in policiesList"
          :class="['policy', { 'text-success': policy.matched }]"
          :key="policy.name"
          data-testid="policy"
        >
          <i :class="['fa', policy.matched ? 'fa-check' : 'fa-close']"></i
          >{{ $t(policy.name) + ": " + policy.value }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { escapeRegExp } from "@/utils";

export default {
  name: "InputPassword",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "password"
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    usePolicies: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id: "input-password-" + this.$utils.uuid(),
      passwordVisible: false,
      isValid: true,
      policiesLoading: false,
      password: "",
      hasFocus: false
    };
  },
  computed: {
    policiesList() {
      if (!this.usePolicies) return [];
      const passwordPolicies = [
        "min_password_length",
        "max_password_length",
        "min_numbers",
        "min_lower_characters",
        "min_upper_characters",
        "min_special_characters"
      ];
      return this.authPolicies
        ? Object.keys(this.authPolicies)
          .filter(
            (key) =>
              passwordPolicies.includes(key) && this.authPolicies[key] > 0
          )
          .sort(
            (a, b) =>
              passwordPolicies.indexOf(a) - passwordPolicies.indexOf(b)
          )
          .map((name) => ({
            name,
            value: this.authPolicies[name],
            matched: new RegExp(
              this.getPatternForPolicy({
                name,
                value: this.authPolicies[name]
              })
            ).test(this.password),
            pattern: this.getPatternForPolicy({
              name,
              value: this.authPolicies[name]
            })
          }))
        : [];
    },
    computedPattern() {
      if (this.pattern) return escapeRegExp(this.pattern);

      let base = "^";
      this.policiesList.forEach((policy) => {
        base += policy.pattern;
      });
      base += ".*$";
      return base;
    },
    minLength() {
      return this.usePolicies
        ? this.authPolicies?.min_password_length || null
        : null;
    },
    maxLength() {
      return this.usePolicies
        ? this.authPolicies?.max_password_length || null
        : null;
    },
    feedbackIcon() {
      if (this.policiesLoading) return "fa fa-undo fa-spin";
      if (this.loadingError) return "fa fa-warning";
      return this.passwordVisible ? "fa fa-eye" : "fa fa-eye-slash";
    },
    ...mapGetters("user", ["authPolicies"])
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.password != value) {
          this.password = value;
        }
      }
    }
  },
  methods: {
    validatePassword(e) {
      let input = e.target;
      let password = input.value;
      this.isValid = input.checkValidity();
      this.$emit("input", password);
    },
    togglePasswordVisibility() {
      if (!this.policiesLoading && !this.loadingError) {
        this.passwordVisible = !this.passwordVisible;
      }
    },
    loadPoliciesIfNeeded() {
      if (!this.authPolicies) {
        this.policiesLoading = true;
        this.fetchAuthPolicies()
          .then(() => {
            this.policiesLoading = false;
          })
          .catch((e) => {
            this.policiesLoading = false;
            this.loadingError = e.message;
          });
      }
    },
    onInputFocus() {
      this.hasFocus = true;
      this.loadPoliciesIfNeeded();
    },
    onInputBlur() {
      this.hasFocus = false;
    },
    getPatternForPolicy(policy) {
      switch (policy.name) {
        case "min_numbers":
          if (policy.value > 0) {
            return `(?=(?:\\D*\\d){${policy.value}})`;
          }
          break;
        case "min_lower_characters":
          if (policy.value > 0) {
            return `(?=(?:[^a-z]*[a-z]){${policy.value}})`;
          }
          break;
        case "min_upper_characters":
          if (policy.value > 0) {
            return `(?=(?:[^A-Z]*[A-Z]){${policy.value}})`;
          }
          break;
        case "min_special_characters":
          if (policy.value > 0) {
            return `(?=(?:[A-Za-z0-9]*[^A-Za-z0-9]){${policy.value}})`;
          }
          break;
        case "min_password_length":
          if (policy.value > 0) {
            return `(?=^.{${policy.value},}$)`;
          }
          break;
        case "max_password_length":
          if (policy.value > 0) {
            return `(?=^.{0,${policy.value}}$)`;
          }
          break;
      }
      return "";
    },
    ...mapActions("user", ["fetchAuthPolicies"])
  }
};
</script>

<style scoped>
.help-block {
  list-style: none;
  padding-left: 0;
}

.policy i {
  margin-right: 5px;
  width: 14px;
}

.form-control-feedback {
  pointer-events: all;
  cursor: pointer;
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 200ms;
}

.fade-up-enter,
.fade-up-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
