<template>
  <input
    :class="{ 'is-dirty': isDirty }"
    @input="update"
    v-bind="$attrs"
    v-on="listeners"
    ref="input"
  />
</template>

<script>
import omit from "lodash/omit";

export default {
  name: "BaseInput",
  inheritAttrs: false,
  props: {
    disableIsDirty: {
      type: Boolean,
      default: () => false,
      required: false
    }
  },
  data() {
    return {
      isDirty: false
    };
  },
  computed: {
    listeners() {
      return omit(this.$listeners, "input");
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    update(e) {
      this.isDirty = true ? !this.disableIsDirty : false;
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<style scoped>
.is-dirty:focus:invalid {
  border-color: #dd4b39;
  box-shadow: none;
}
</style>
