<template>
  <div class="login-box fade-in">
    <div class="login-logo">
      <a v-bind:href="website">
        <img v-bind:src="img_banner" class style="width: 90%" />
      </a>
    </div>
    <div class="login-box-body">
      <p class="login-box-msg text-warning" v-if="error">
        <i class="fa fa-exclamation-triangle"></i>
        {{ $tc(error) }}
      </p>
      <p class="login-box-msg" v-else>
        {{ $t("please_fill_this_form_to_create_an_account") }}
      </p>
      <form
        action="/dashboard"
        accept-charset="UTF-8"
        method="post"
        autocomplete="off"
        @submit.prevent="onSubmit"
      >
        <div class="row">
          <div
            class="form-group col-xs-12"
            :class="{ 'has-error': !isValidName }"
          >
            <label for="full_name">{{ $t("full_name") }}</label>
            <BaseInput
              name="full_name"
              type="text"
              data-testid="full_name"
              class="form-control"
              v-bind:placeholder="$t('full_name')"
              required
              v-model="full_name"
              autocomplete="off"
              ref="fullName"
              pattern=".*"
              :title="$t('insert_full_name')"
              :disableIsDirty="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" :class="{ 'has-error': !isValidEmail }">
              <label for="usr">{{ $t("email") }}</label>
              <BaseInput
                name="usr"
                type="email"
                data-testid="email"
                class="form-control"
                :placeholder="$tc('email')"
                required
                v-model="usr"
                ref="usr"
                autocomplete="off"
                :disableIsDirty="true"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5">
            <div class="form-group" :class="{ 'has-error': !isValidPhone }">
              <label for="usr">{{ $t("mobile") }}</label>
              <InputMasked id="phone" type="phone" v-model="phone_number" />
            </div>
          </div>
          <div class="col-sm-7">
            <div
              class="form-group"
              :class="{ 'has-error': !isValidCompanyName }"
            >
              <label for="usr">{{ $t("company") }}</label>
              <input
                name="company"
                type="text"
                data-testid="company"
                class="form-control"
                :placeholder="$t('company')"
                required
                v-model="company"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group" :class="{ 'has-error': !isValidPassword }">
              <InputPassword v-model="pwd1" :use-policies="true" />
            </div>
          </div>
        </div>
        <div class="row mb-20">
          <div class="col-xs-12 text-center">
            <Terms v-model="terms" />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 text-center">
            <template v-if="isWhiteLabel">
              <button
                data-testid="submit"
                class="btn btn-lg btn-primary"
                v-bind:disabled="!isValidForm"
                type="submit"
                :title="buttonTitle"
              >
                <img
                  style="width: 32px; margin-right: 5px"
                  src="/static/common/images/pacote_basic80.png"
                />
                {{ $t("confirm") }}
              </button>
            </template>
            <template v-else>
              <button
                data-testid="submit"
                class="btn btn-lg btn-primary"
                v-bind:disabled="!isValidForm"
                type="submit"
                :title="buttonTitle"
              >
                <img
                  style="width: 32px; margin-right: 5px"
                  :src="plan ? plan.image : ''"
                />
                {{ $t("confirm") }} {{ planInfo }}
                <div class="commitment">
                  {{ $t(commitment) }}
                </div>
              </button>
              <div
                style="margin-top: 10px"
                @click.stop.prevent="openPlanSelector"
              >
                <a href="#">Selecionar outro plano</a>
              </div>
            </template>
          </div>
        </div>
      </form>
      <div class="row mt-20">
        <div class="col-xs-12">
          <router-link to="/" data-testid="login-link">{{
            $t("already_have_an_account")
          }}</router-link>
        </div>
      </div>
      <div class="row mt-12">
        <div class="col-xs-12">
          <router-link to="/auth/activate" data-testid="login-link">{{
            $t("resend_the_activation_code")
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="busy">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <Version />
    <Modal
      :open="showSelectPlan"
      @update:open="showSelectPlan = $event ? true : false"
      :title="$t('select_plan')"
      :footer="false"
      iconClass="fa fa-credit-card"
      :fitContent="false"
      :backdrop="busy ? 'static' : 'true'"
      :component_busy="busy"
    >
      <template #content>
        <UserPlanPricing
          :payload="payload"
          :showTerms="false"
          :buttonText="$t('select')"
          :markContractedPlan="false"
          @upgrade="onPlanChanged"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import greCAPTCHA from "@/plugins/grecaptcha.js";
import AuthService from "@/services/auth";
import Terms from "@/components/terms.vue";
import Version from "@/components/version.vue";
import Pricing from "@/assets/dashboard/pricing.json";
import { contractPlanConst } from "@/assets/constants.js";
import UserPlanPricing from "@/components/user-plan/user-plan-pricing.vue";
import InputPassword from "@/components/registration/input-password.vue";
import BaseInput from "@/components/base/base-input.vue";
import InputMasked from "@/components/input-masked.vue";
import Modal from "@/components/widgets/modal.vue";

const findPlan = (query) =>
  (Pricing?.regular || []).find((plan) =>
    isNaN(parseInt(query)) ? plan.name == query : plan.id == query
  );
export default {
  name: "FormSignUp",
  props: {
    planName: {
      type: String,
      default: "basic",
      required: false
    }
  },
  components: {
    Terms,
    Version,
    UserPlanPricing,
    InputPassword,
    InputMasked,
    BaseInput,
    Modal
  },
  data: function() {
    return {
      usr: "",
      pwd: false,
      pwd1: "",
      full_name: "",
      company: "",
      phone_number: "",
      terms: false,
      busy: false,
      grecaptcha: null,
      showSelectPlan: false,
      payload: {
        plan_identity_number: contractPlanConst.BASIC,
        plan_data_range: 50,
        is_yearly: false
      }
    };
  },
  computed: {
    isReady: function() {
      return this.$store.getters["dashboard/isReady"];
    },
    img_banner: function() {
      return (
        this.$store.getters["dashboard/property"]("company_banner") ||
        "/static/common/images/loading-login-default.gif"
      );
    },
    website: function() {
      return (
        this.$store.getters["dashboard/property"]("company_website") ||
        "https://www.hitecnologia.com.br"
      );
    },
    error: function() {
      return this.$store.getters["user/loginError"];
    },
    names() {
      return this.$utils.trim(this.full_name).split(" ");
    },
    firstName() {
      return this.names.length >= 1 ? this.names[0] : "";
    },
    lastName() {
      return this.names.length >= 2
        ? JSON.parse(JSON.stringify(this.names))
          .splice(1)
          .join(" ")
        : "";
    },
    isValidName() {
      return this.firstName && this.lastName && this.firstName != this.lastName;
    },
    isValidEmail() {
      return this.$utils.validateEmail(this.usr);
    },
    isValidCompanyName() {
      return this.company && this.company.trim() != "";
    },
    isValidPhone() {
      return this.$utils.validatePhone(this.phone_number);
    },
    isValidPassword() {
      return this.pwd1;
    },
    isValidForm() {
      return this.terms &&
        this.usr &&
        this.isValidName &&
        this.isValidPassword &&
        this.isValidCompanyName &&
        this.isValidPhone &&
        this.isValidEmail
        ? true
        : false;
    },
    phoneNumber() {
      let re = (this.phone_number || "").match(/[0123456789]+/g);
      if (re) {
        return re.join("");
      }
      return "";
    },
    payloadComputed() {
      if (!this.isValidForm) return null;
      btoa("");
      let pwd = Buffer.from(this.pwd1, "binary").toString("base64");
      let payload = {
        email: this.usr,
        password1: pwd,
        password2: pwd,
        first_name: this.firstName,
        last_name: this.lastName,
        company_name: this.company || "",
        phone: this.phoneNumber
      };

      if (!this.isWhiteLabel) {
        payload = { ...payload, ...this.payload };
      }

      payload.transparent_checkout = false;

      return payload;
    },
    g_site_token() {
      return (
        this.$store.getters["dashboard/property"]("grecaptcha_site_token") || ""
      );
    },
    plan() {
      return findPlan(this.payload.plan_identity_number);
    },
    planInfo() {
      return `${this.plan.title} + ${this.payload.plan_data_range} ${this.$tc(
        "data",
        2
      )}`;
    },
    planSelectorFilter() {
      return {
        migrating: false,
        whitelabel: false,
        yearly: false,
        demo: false,
        maximumData: this.payload.plan_data_range || 50,
        plan: this.plan.name || this.planName
      };
    },
    commitment() {
      return this.payload.is_yearly ? "yearly" : "monthly";
    },
    isWhiteLabel() {
      return this.$root.config.is_white_label || false;
    },
    buttonTitle() {
      if (!this.isValidForm) {
        return this.$t("register_fill_all_fields");
      } else {
        return this.$t("register_contract_selected_plan");
      }
    }
  },
  watch: {
    planName: {
      handler(n) {
        if (n) {
          let plan = findPlan(n);
          this.payload.plan_identity_number = plan
            ? plan.id
            : contractPlanConst.BASIC;
        }
      },
      immediate: true
    }
  },
  methods: {
    onError(details) {
      let self = this;
      self.$swal({
        title: self.$t("an_error_has_occurred"),
        text: details,
        icon: "error"
      });
    },
    onSuccess(callback) {
      let _cb = callback || function() { };
      let self = this;
      self
        .$swal({
          title: self.$t("congratulation"),
          text: self.$t("signup_success_message"),
          icon: "success"
        })
        .then((result) => {
          _cb();
        });
    },
    save(token) {
      let self = this;
      if (!this.isValidForm) return false;
      self.busy = true;
      var payload = self.payloadComputed;
      if (token) {
        payload.grecaptcha = token;
      }
      let auth = new AuthService();
      auth.save(payload).then((response) => {
        self.busy = false;
        if (response) {
          if (typeof response == "object") {
            self.onSuccess(function() {
              self.$router.push("/");
            });
          } else {
            self.onError(response);
          }
        } else {
          self.onError(self.$t("sorry_we_could_not_perform_your_request"));
        }
      });
    },
    onSubmit(e) {
      var self = this;
      if (self.isValidForm) {
        if (self.grecaptcha) {
          self.grecaptcha
            .execute(self.g_site_token, { action: "register" })
            .then(function(token) {
              self.save(token);
            });
        } else {
          self.save();
        }
      }
    },
    onPlanChanged(entry) {
      this.showSelectPlan = false;
      this.$set(this, "payload", entry);
    },
    openPlanSelector() {
      this.showSelectPlan = true;
    }
  },
  created: function() {
    let self = this;
    self.grecaptcha = null;
    if (self.g_site_token) {
      (async function() {
        self.grecaptcha = await greCAPTCHA(self.g_site_token);
      })();
    }
  },
  mounted: function() {
    if ("plan_data_range" in (this?.$route?.query || {})) {
      this.payload.plan_data_range = this?.$route?.query?.plan_data_range;
    }
    if ("is_yearly" in (this?.$route?.query || {})) {
      this.payload.is_yearly = this?.$route?.query?.is_yearly;
    }
    this.$nextTick(() => this.$refs.fullName.focus());
  }
};
</script>

<style scoped>
.login-logo {
  min-height: 70px;
}

.login-box {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 470px;
}

.login-box-body,
.register-box-body {
  border: 1px solid #dcdada;
  padding: 20px;
  border-radius: 4px;
}

.form-check span {
  vertical-align: top;
}

.login-links {
  line-height: 2em;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.login-box .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}

.login-box .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}

.form-control-feedback {
  pointer-events: all;
}

::v-deep .modal-dialog {
  min-width: 1200px;
}

@media (max-width: 1720px) {
  ::v-deep .modal-dialog {
    min-width: 1000px;
  }
}
@media (max-width: 1200px) {
  ::v-deep .modal-dialog {
    min-width: 1190px;
  }
}
@media (max-width: 992px) {
  ::v-deep .modal-dialog {
    min-width: 940px;
  }
}
@media (max-width: 768px) {
  .login-box {
    width: 97%;
  }
  ::v-deep .modal-dialog {
    min-width: inherit;
  }
}

div.modal {
  background: rgba(0, 0, 0, 0.01);
}

div.modal > div.modal-dialog {
  margin: 30px auto 0 auto;
}

div.modal > div.modal-dialog > div.modal-content {
  border-radius: 10px;
}

div.modal > div.modal-dialog > div.modal-content > div.modal-body {
  position: relative;
  padding: 0;
  zoom: 0.9;
}

input.form-control,
input.input-mask-below,
input.input-mask-over {
  padding: 0 5px;
}
.commitment {
  margin: -5px 0 0 0;
  padding: 0;
  line-height: 0.5em;
  font-size: 70%;
  color: #cbd0e8;
}
</style>
